<template>
  <div class="space-y-4">
    <asom-modal
      title="Create Shift End Summary"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form>
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <div class="pt-8">
          <p class="pb-2 font-sans font-semibold text-gray-500">
            Pre-Encoded Ticket Shift End Summary
          </p>
          <div
            class="w-96 relative rounded-lg bg-gray-100 px-6 py-5 shadow items-center"
          >
            <div class="p-2 h-10 w-10 bg-button rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="pt-6">
              <div>
                <span class="font-sans text-button">${{ soldTickets }}</span>
                <span class="font-sans text-gray-500"> from Sold Tickets</span>
              </div>
              <div>
                <span class="font-sans text-button">${{ refunded }}</span>
                <span class="font-sans text-gray-500">
                  from Tickets Leftover (Refunded)</span
                >
              </div>
              <div>
                <span class="font-sans text-button">{{ noOfCashBags }}</span>
                <span class="font-sans text-gray-500">
                  Cash Bags Generated</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.push({ name: 'Pre Encoded Ticket Main Page' })"
          />
          <asom-button
            text="Declare Cash Bag"
            variant="secondary"
            @click="$router.push({ name: 'Cash Declaration Form Create' })"
          />
          <asom-button
            text="Confirm Shift End"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
    <div class="pt-6">
      <div>Pre-Encoded Transaction logs</div>
      <div class="p-4">Current Shift: {{ shift }}</div>
      <asom-client-table
        :columns="[
          'transactionNo',
          'event',
          'dateCreated',
          'transactionType',
          'noOfTickets',
          'ticketValue',
        ]"
        :data="getTableData"
        :sortableColumns="[
          'transactionNo',
          'event',
          'dateCreated',
          'transactionType',
          'noOfTickets',
          'ticketValue',
        ]"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:header_transactionNo>Transaction No</template>
        <template v-slot:ticketValue="scopedSlots">
          <span>{{ formatCurrency(scopedSlots.data) }}</span>
        </template>
        <template v-slot:event="scopedSlots">
          <span>{{ scopedSlots.data.eventName }}</span>
        </template>
        <template v-slot:header_dateCreated>Date</template>
        <template v-slot:header_transactionType>Transaction Type</template>
        <template v-slot:header_noOfTickets>No of Tickets</template>
        <template v-slot:header_ticketValue>Ticket Value</template>
        <template v-slot:dateCreated="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
      </asom-client-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import filter from "lodash.filter";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getPreEncodedMainPageData,
  createShiftEndSummary,
} from "../../../../services/cashManagement.service";
import { formatCurrency } from "../../../../helpers/numbers";
import { psc } from "../../../../constants/APIEnums/cashManagementEnums";

export default {
  name: "PETShiftEndSummary",
  data() {
    return {
      showModal: false,
      ticketLogs: [],
      isSubmitting: false,
      error: null,
      soldTickets: null,
      transactionIds: null,
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      shift: "currentRoster/currentRosterShift",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    getTableData() {
      return this.ticketLogs;
    },
    refunded() {
      return 0;
    },
    noOfCashBags() {
      return 0;
    },
  },
  mounted() {
    this.getTicketLogs();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getTicketLogs();
    },
  },
  methods: {
    formatCurrency,
    displayUtcDate,
    async getTicketLogs() {
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const resp = await getPreEncodedMainPageData({
        pSCId: this.pscId,
      });
      if (resp.success) {
        this.ticketLogs = get(resp.payload.data, "transactions", []);
        this.isLoading = false;
        this.error = null;
        this.setData();
      } else {
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    setData() {
      let total = 0;
      let issued = filter(this.ticketLogs, {
        transactionType: psc.preEncodedTicket.TRANSACTION_TYPE.ISSUE.NAME,
        addedToShiftEnd: false,
      });
      this.ticketLogs = issued;
      issued.forEach((log) => {
        total += log.amount;
      });
      this.soldTickets = total;
      this.transactionIds = this.ticketLogs.map((log) => log.transactionId);
    },
    submitClicked() {
      this.showModal = true;
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await createShiftEndSummary({
        shiftEndSummaryType: 2,
        psmMachineAmount: 0,
        psmTransactionIds: [],
        preEncodedTicketTransactionIds: this.transactionIds,
        cashFloatTransactionIds: [],
        attachmentIds: [],
        accountId: this.pscId,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "Pre Encoded Ticket Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
